import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container, Image, Navbar, Row, Col } from "react-bootstrap"
import NavImageLogo from "../images/logo.png"
import Helmet from "react-helmet"

import "../components/skbio.css"
import Seo from "../components/seo";


export default function SkbioPost({ data }) {
  const skbio = data.allWpSkwprdc.nodes[0]
  console.log(skbio)
  return (
    <Layout>
      <Seo title={skbio.skwprdc.name} />
      <Helmet>
        <script src="https://kit.fontawesome.com/bb17528802.js" crossorigin="anonymous"></script>
      </Helmet>

      <Navbar expand="lg" bg="dark" variant="dark" className="py-3">
        <Container>
          <Navbar.Brand>
            <img
              src={NavImageLogo}
              width="42"
              height="42"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar className="justify-content-end">
            <Navbar.Text className="h2" style={{ color: "white" }}>
              {skbio.skwprdc.name}
            </Navbar.Text>
          </Navbar>
        </Container>
      </Navbar>

      <br></br>
      <Container className="maxWidthSizeCustom">
        <Image variant="top" src={skbio.skwprdc?.profilePicture?.sourceUrl} height="320" alt={skbio.skwprdc?.profilePicture?.altText} rounded fluid />
        <br></br>
        <br></br>
        <div class="d-grid d-sm-flex justify-content-center">
          <ul class="list-unstyled d-flex">
            <a href={skbio.skwprdc.igLink} target="_blank"><li><span class="fa-stack fa-2x" style={{ color: "black" }}>
              <i class="far fa-circle fa-stack-2x"></i>
              <i class="fab fa-instagram fa-stack-1x"></i>
            </span></li></a>
            <a href={skbio.skwprdc.igLink} target="_blank"><li><span class="fa-stack fa-2x" style={{ color: "black" }}>
              <i class="far fa-circle fa-stack-2x"></i>
              <i class="fab fa-twitter fa-stack-1x"></i>
            </span></li></a>
            <a href={skbio.skwprdc.igLink} target="_blank"><li><span class="fa-stack fa-2x" style={{ color: "black" }}>
              <i class="far fa-circle fa-stack-2x"></i>
              <i class="fab fa-youtube fa-stack-1x"></i>
            </span></li></a>
          </ul>
        </div>
        <div dangerouslySetInnerHTML={{ __html: skbio.skwprdc.bio }} />

        <div>
          <Row xs={1} md={2} lg={2}>
            <Col md={6}>
              <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                  <Image variant="top" src={skbio.skwprdc?.portfolioItem1Picture?.sourceUrl} width="852" height="480" alt={skbio.skwprdc?.profilePicture?.altText} fluid />
                  <h3 class="mb-0 SerifFont"><b>{skbio.skwprdc.portfolioItem1Name}</b></h3>
                  <span class="border-bottom border-dark"></span>
                  <br></br>
                  <Link to={skbio.skwprdc.portfolioItem1Url}><Button variant="dark">Read More</Button></Link>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                  <h3 class="mb-0 SerifFont"><b>{skbio.skwprdc.portfolioItem1Name}</b></h3>
                  <br></br>
                  <Image variant="top" src={skbio.skwprdc?.portfolioItem1Picture?.sourceUrl} width="852" height="480" alt={skbio.skwprdc?.profilePicture?.altText} fluid />
                  <br></br>
                  <span class="border-bottom border-dark"></span>
                  <Link to={skbio.skwprdc.portfolioItem1Url}><Button variant="dark">Read More</Button></Link>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                  <h3 class="mb-0 SerifFont"><b>{skbio.skwprdc.portfolioItem1Name}</b></h3>
                  <br></br>
                  <Image variant="top" src={skbio.skwprdc?.portfolioItem1Picture?.sourceUrl} width="852" height="480" alt={skbio.skwprdc?.profilePicture?.altText} fluid />
                  <br></br>
                  <span class="border-bottom border-dark"></span>
                  <Link to={skbio.skwprdc.portfolioItem1Url}><Button variant="dark">Read More</Button></Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpSkwprdc(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        skwprdc {
          bio
          igLink
          name
          profilePicture {
            altText
            sourceUrl
          }
          portfolioItem1Name
          portfolioItem1Url
          portfolioItem1Picture {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`
